import { useModalStore } from '~/stores'
export default defineNuxtRouteMiddleware((to, from) => {
    const useModalStoreInstance = useModalStore()
    if (import.meta.server) {
        const user = useCookie('user')
        if (!user.value) {
            abortNavigation()
            return navigateTo('/')
        }
    } else {
        const userStoreFromStorage = localStorage.getItem('userStore')
        const userStore = JSON.parse(userStoreFromStorage)
        const user = userStore?.user
        if (!user) {
            abortNavigation()
            useModalStoreInstance.showLoginModal = true
            // await useUserStoreInstance.refresh()
            if (from.path.includes('account')) {
                return (window.location = '/')
            } else {
                return navigateTo(from.path)
            }
        }
    }
})
